import React from "react"

export default function Navbar(props) {
    
    return (
        <div className="navbar">
            <h4 className="navbar--title">my travel jounal</h4>
        </div>
        
    )     
        
        
    
    
    }
    